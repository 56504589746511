<template>
  <div id="about_info" class="about_info">
    <div class="container w1400">
      <div class="fliter_outer">
        <MenuClassificationFilter
          :Classification="Classification"/>
      </div>
      <div class="article_outer">
        <Article
          :articleData="articleData"/>
        <!-- <h5 v-if="articleId === '2' && classification == 'ivf'" class="m_title txt-bold txt-theme" style="padding-left: 2.4em; line-height:3em">針劑教學影片：</h5>
        <div v-if="articleId === '2' && classification == 'ivf'" style="margin: 0em 0em 0em 3em; width: 90%; padding: 0 3em 0 0">
          <video controls style="width: 100%">
            <source src="@/assets/img/nd_1.mp4" type="video/mp4">
          </video>
        </div>
        <div v-if="articleId === '2' && classification == 'ivf'" style="margin: 0em 0em 0em 3em; width: 90%; padding: 0 3em 0 0">
          <video controls style="width: 100%">
            <source src="@/assets/img/nd_2.mp4" type="video/mp4">
          </video>
        </div> -->
        <router-link :to="btnData.link" class="btn"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        v-show="(classification === 'ivf' || classification === 'tcm') && btnData.link === '/registration'">
          {{btnData.text}}
        </router-link>
        <a :href="btnData.link"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        target="_blank" class="btn" v-show="(classification === 'ivf' || classification === 'tcm') && btnData.link !== '/registration'">
          {{btnData.text}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import '@/assets/scss/index.scss';
// import IndexKv from '@/components/index/IndexKv.vue';
import Article from '@/components/Article.vue';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';

import {
  getProcreationArticles,
  getAssignProcreationArticle,
  getTraditionalArticles,
  getLabsArticles,
  getAssignTraditionalArticle,
  getAssignLabsArticle,
  getAssignProcreationArticlePreview,
  getAssignTraditionalArticlePreview,
  // eslint-disable-next-line
  getAssignLabsArticlePreview,
} from '@/lib/public';

import { mapMutations, mapGetters } from 'vuex';

import '@/assets/scss/about.scss';

export default {
  name: 'aboutInfo',
  components: {
    Article,
    MenuClassificationFilter,
  },
  props: ['classification', 'articleId'],
  data() {
    return {
      Classification: [
        {
          path: '',
          linkName: '',
        },
      ],
      articleData: {
        childArticle: null,
        data: null,
      },
      btnData: {
        link: '/registration',
        text: this.$t('bookingNow'),
      },
      path: this.$route.path,
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
      'getLanguage',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    changeFliter() {
      if (this.classification === 'ivf') {
        this.Classification = [];
        getProcreationArticles(
          this.getLangUUID,
          (response) => {
            console.log(response.data.data);
            response.data.data.forEach((elem) => {
              const temp = {
                path: `/aboutinfo/ivf/${elem.slug}`,
                linkName: elem.title,
              };
              this.Classification.push(temp);
            });
          },
        );
      } else if (this.classification === 'tcm') {
        this.Classification = [];
        getTraditionalArticles(
          this.getLangUUID,
          (response) => {
            console.log(response.data.data);
            response.data.data.forEach((elem) => {
              const temp = {
                path: `/aboutinfo/tcm/${elem.slug}`,
                linkName: elem.title,
              };
              this.Classification.push(temp);
            });
          },
        );
      } else if (this.classification === 'labs') {
        this.Classification = [];
        getLabsArticles(
          this.getLangUUID,
          (response) => {
            console.log(response.data.data);
            response.data.data.forEach((elem) => {
              const temp = {
                path: `/aboutinfo/labs/${elem.slug}`,
                linkName: elem.title,
              };
              this.Classification.push(temp);
            });
          },
        );
      }
    },
    changeArticle() {
      this.articleData = {
        childArticle: null,
        data: null,
      };

      // 定義取得指定療程文章api回傳值之後的處理
      const assignArticleResponseFunction = (response) => {
        const articleInfo = response.data.data;
        const childArticleTemp = [];
        let articleInfoTemp = '';

        // 掛號按鈕內容帶入
        this.btnData = {
          link: articleInfo.btn_slug !== null ? articleInfo.btn_slug : '/registration',
          text: articleInfo.btn_text !== null ? articleInfo.btn_text : this.$t('bookingNow'),
        };

        // 用子文章標題取得子文章的數目
        articleInfo.subContents.forEach((elem, i) => {
          if (elem.sub_title) {
            childArticleTemp.push({
              path: `/aboutinfo/${this.classification}/${articleInfo.slug}?sub=${i + 1}`,
              linkName: elem.sub_title,
            });
          }
        });

        // 顯示主文章or子文章
        articleInfoTemp = articleInfo.content;
        if (this.$route.query.sub) {
          articleInfoTemp = articleInfo.subContents[this.$route.query.sub - 1].content;
        }

        // 如果有子文章, 直接切到第一篇子文章
        if (childArticleTemp.length !== 0 && !this.$route.query.sub) {
          this.$router.replace(`/aboutinfo/${this.classification}/${articleInfo.slug}?sub=1`);
        }

        // 變更data
        this.articleData = {
          childArticle: childArticleTemp,
          data: [
            {
              type: 1,
              title: articleInfo.title,
            },
            {
              type: 10,
              text: articleInfoTemp,
            },
          ],
        };
      };

      // 預覽文章選單處理
      const menuProcess = (response) => {
        const { slug, title } = response.data.data;
        if (this.classification === 'ivfpreview') {
          const temp = {
            path: `/preview/aboutinfo/ivfpreview/${slug}`,
            linkName: title,
          };
          this.Classification = [temp];
        } else if (this.classification === 'tcmpreview') {
          const temp = {
            path: `/preview/aboutinfo/tcmpreview/${slug}`,
            linkName: title,
          };
          this.Classification = [temp];
        } else if (this.classification === 'labspreview') {
          const temp = {
            path: `/preview/aboutinfo/labspreview/${slug}`,
            linkName: title,
          };
          this.Classification = [temp];
        }
      };

      if (this.classification === 'ivf') {
        getAssignProcreationArticle(
          this.articleId,
          (response) => {
            assignArticleResponseFunction(response);
          },
        );
      } else if (this.classification === 'tcm') {
        getAssignTraditionalArticle(
          this.articleId,
          (response) => {
            assignArticleResponseFunction(response);
          },
        );
      } else if (this.classification === 'labs') {
        getAssignLabsArticle(
          this.articleId,
          (response) => {
            assignArticleResponseFunction(response);
          },
        );
      } else if (this.classification === 'ivfpreview') {
        getAssignProcreationArticlePreview(
          this.articleId,
          (response) => {
            const f1 = assignArticleResponseFunction(response);
            Promise.all([f1]).then(() => {
              console.log(response);
              menuProcess(response);
            });
          },
        );
      } else if (this.classification === 'tcmpreview') {
        getAssignTraditionalArticlePreview(
          this.articleId,
          (response) => {
            const f1 = assignArticleResponseFunction(response);
            Promise.all([f1]).then(() => {
              console.log(response);
              menuProcess(response);
            });
          },
        );
      } else if (this.classification === 'labspreview') {
        getAssignLabsArticlePreview(
          this.articleId,
          (response) => {
            const f1 = assignArticleResponseFunction(response);
            Promise.all([f1]).then(() => {
              console.log(response);
              menuProcess(response);
            });
          },
        );
      } else {
        this.$router.push('/Home');
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.changeFliter();
      this.changeArticle();
    }
  },
  watch: {
    $route() {
      // console.log(to, from);
      // console.log(this.classification, this.articleId);
      // this.changeFliter();
      this.changeArticle();
    },
    getLangUUID(val) {
      if (val !== null) {
        this.changeFliter();
        this.changeArticle();
      }
    },
  },
};
</script>
